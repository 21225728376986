<template>
  <div>
    <div class="d-flex align-center py-2">
      <div>
        <v-icon class="mr-2" color="primary">{{ icon[value.origin] }}</v-icon>
      </div>
      <div>
        {{ text }}
        <span v-if="origin == 'order'">#{{ sale.code }}</span>
        <br />
        <small v-if="origin == 'order'" class="text--secondary">
          {{ customer }}</small
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
  },

  data() {
    return {
      icon: {
        order: "mdi-cart-outline",
        custom: "mdi-cash",
      },
    };
  },

  computed: {
    origin() {
      return this.value.origin;
    },
    sale() {
      return this.value.sale_id ? this.value.sale : null;
    },
    customer() {
      return this.sale.customer_id
        ? this.value.sale.customer.name
        : " Consumidor Final";
    },
    text() {
      return this.origin == "order"
        ? "Lançamento ref. venda"
        : this.value.description;
    },
  },
};
</script>

<style></style>
