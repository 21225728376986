<template>
  <div>
    <v-row class="mt-4">
      <v-col>
        <v-btn
          class="text-capitalize mr-4"
          @click="openShiftSelectDialog()"
          bottom
          outlined
          color="primary"
          >Caixas Anteriores</v-btn
        >

        <v-btn
          class="text-capitalize mr-4"
          bottom
          v-if="!isShiftOpen(selectedShift) && canUpdateShift"
          outlined
          color="primary"
          @click="openShift()"
          >Abrir Caixa</v-btn
        >
        <v-btn
          class="text-capitalize mr-4"
          bottom
          outlined
          v-if="isPreviousShift"
          color="primary"
          @click="getOpenedShift()"
          >Voltar para Caixa atual</v-btn
        >
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="2">
        <v-menu v-if="canAddNew" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="text-capitalize"
              bottom
              large
              color="primary"
              >Novo
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="openStoreAccountTransaction('in')" link>
              <v-list-item-title>
                <v-icon color="success">mdi-arrow-top-right</v-icon>Nova Entrada
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="openStoreAccountTransaction('out')" link>
              <v-list-item-title>
                <v-icon color="error">mdi-arrow-bottom-left</v-icon>Nova Saída
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-if="selectedShift">
      <v-col class="mr-4" cols="9">
        <account-transactions-list
          :loading="loading"
          @click:row="setView($event)"
          ref="AccountTransactionsList"
          :accountTransactions="accountTransactions"
        />
        <v-pagination
          v-model="params.page"
          :length="accountTransactions.last_page"
          @input="handlePageChange($event)"
          :total-visible="9"
        />
      </v-col>
      <v-col class="ml-8">
        <account-resume
          :loading="loading"
          :accountTransactionsByPaymentMethod="
            accountTransactionsByPaymentMethod
          "
          :selectedShift="selectedShift"
          :canCloseShift="canAddNew"
          :balance="balance"
          @closeShift="openCloseShiftDialog()"
          ref="AccountResume"
        />
      </v-col>
    </v-row>
    <open-shift-dialog @store="getAccounts()" ref="OpenShiftDialog" />
    <close-shift-dialog @close="getAccounts()" ref="CloseShiftDialog" />
    <shift-select-dialog @input="loadShift($event)" ref="ShiftSelectDialog" />
    <store-account-transaction @store="boot()" ref="StoreAccountTransaction" />
    <OrderViewDialog ref="OrderViewDialog" />
    <AccountTransactionDialog ref="AccountTransactionDialog" />
  </div>
</template>

<script>
import AccountTransactionsList from "../components/account/lists/AccountTransactionsList.vue";
import CloseShiftDialog from "../components/account/section/CloseShiftDialog.vue";
import OpenShiftDialog from "../components/account/section/OpenShiftDialog";
import ShiftSelectDialog from "../components/account/section/ShiftSelectDialog.vue";
import AccountResume from "../components/account/ui/AccountResume.vue";
import StoreAccountTransaction from "../components/account/form/StoreAccountTransaction.vue";
import OrderViewDialog from "../components/orders/dialos/OrderViewDialog.vue";
import AccountTransactionDialog from "../components/account/dialogs/AccountTransactionDialog.vue";
import Shift from "@/mixins/Account/Shift";

export default {
  components: {
    AccountTransactionsList,
    AccountResume,
    OpenShiftDialog,
    CloseShiftDialog,
    ShiftSelectDialog,
    StoreAccountTransaction,
    OrderViewDialog,
    AccountTransactionDialog,
  },
  mixins: [Shift],

  data() {
    return {
      balance: {},
      accountTransactionsByPaymentMethod: [],
      accountTransactions: {},
      isPreviousShift: false,
      selectedShift: null,
      loading: false,
      params: {
        page: 4,
        shift_id: null,
      },
    };
  },

  async mounted() {
    await this.getAccounts();
  },

  computed: {
    openedShift() {
      return this.$store.state.accounts.selectedAccount.opened_shift;
    },

    account() {
      return this.$store.state.accounts.selectedAccount;
    },
    canAddNew() {
      return !this.isPreviousShift && this.isShiftOpen(this.selectedShift);
    },
    canUpdateShift() {
      return !this.isPreviousShift;
    },
  },
  watch: {
    account() {
      this.boot();
    },
  },

  methods: {
    setView(data) {
      data.origin == "order"
        ? this.showSale(data.sale_id)
        : this.showTransaction(data.id);
    },
    showSale(sale_id) {
      this.$refs.OrderViewDialog.open(sale_id);
    },
    showTransaction(transaction_id) {
      this.$refs.AccountTransactionDialog.open(transaction_id);
    },

    handleSearchInput(text) {
      console.log(text);
    },

    async boot() {
      this.reset();

      if (this.openedShift) {
        this.params.account_id = this.account.id;
        this.params.shift_id = this.openedShift.id;
        this.params.page = 1;

        await this.loadTransactions();
      }
    },
    loadTransactions() {
      this.loading = true;
      this.$http
        .index("account/account-transaction", this.params)
        .then((response) => {
          this.balance = response.balance;
          this.accountTransactions = response.accountTransactions;
          this.accountTransactionsByPaymentMethod =
            response.accountTransactionsByPaymentMethod;
          this.loading = false;
        });
    },
    async loadShift(selectedShift) {
      this.params.shift_id = selectedShift.id;
      this.params.page = 1;

      await this.loadTransactions();
      this.selectedShift = selectedShift;
      if (!this.openedShift) {
        this.isPreviousShift = true;
      } else {
        this.isPreviousShift = this.openedShift.id !== selectedShift.id;
      }
    },
    getOpenedShift() {
      this.boot();
    },
    reset() {
      this.isPreviousShift = false;
      this.accountTransactions = [];
      this.accountResume = null;
      this.selectedShift = this.openedShift;
    },

    async getAccounts() {
      if (!this.selectedAccount) {
        await this.$store.dispatch("accounts/accountIndex").then((response) => {
          this.boot();
        });
      }
    },
    handlePageChange(page) {
      this.params.page = page;
      this.loadTransactions();
    },

    openStoreAccountTransaction(type) {
      this.$refs.StoreAccountTransaction.open(type);
    },

    openShift() {
      this.$refs.OpenShiftDialog.open();
    },

    openCloseShiftDialog() {
      this.$refs.CloseShiftDialog.open();
    },

    openShiftSelectDialog() {
      this.$refs.ShiftSelectDialog.open();
    },
  },
};
</script>

<style>
.dashed-bottom-border {
  border-bottom: dashed 1px rgba(0, 0, 0, 0.12);
}
</style>
