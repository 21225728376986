<template>
  <v-card v-bind="$attrs" color="grey lighten-3 " elevation="0">
    <v-card-text>
      <div class="pb-1 mt-4 d-flex justify-space-between">
        <div><v-icon class="mr-4">mdi-clock</v-icon> Abertura de Caixa</div>
        <div>
          <b v-if="selectedShift">{{$format.dateTimeBr(selectedShift.started_at)}}</b>
        </div>
      </div>
      <div 
        v-if="!canCloseShift"
        class="pb-1 mt-4 d-flex justify-space-between"
      >
      <div><v-icon class="mr-4">mdi-clock</v-icon> Fechamento de Caixa</div>
        <div>
          <b v-if="selectedShift">{{$format.dateTimeBr(selectedShift.ended_at)}}</b>
        </div>
      </div>
      <v-divider class="mt-6 mb-8" />
      <div v-for="(row, index) in paymentMethods" :key="index" class="dashed-bottom-border pb-1 mb-4 d-flex justify-space-between">
        <div>
          <v-icon class="mr-4" v-text="row.icon"> </v-icon>
          {{ row.name }}
        </div>
        <div>
          <b v-if="getSum(row.id)[0]">{{$format.decimal(getSum(row.id)[0].value_sum)}}</b>
          <b v-else>0,00 </b>
        </div>
      </div>
      <div class="text-right mt-8">
        <span class="text--disabled">Saldo do Caixa</span>
        <h1 class="mt-3">R$ {{$format.decimal(balance.balance_sum)}}</h1>
      </div>
      <div>
        <v-btn
          v-if="canCloseShift"
          @click="$emit('closeShift')"
          class="text-capitalize mt-8 mb-0"
          block
          color="primary"
        >
          Fechar Caixa
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    balance:{},
    accountTransactionsByPaymentMethod:{},
    canCloseShift: {},
    selectedShift: {},
  },
  data() {
    return {
      paymentMethods: [],
    };
  },

  mounted() {
    this.$http.index("payment-methods/payment-methods").then((response) => {
      this.paymentMethods = response.paymentMethods;
    });
  },
  methods: {
    getSum(payment_method_id) {
      return this.accountTransactionsByPaymentMethod.filter((row) => {
        return row.payment_method_id == payment_method_id;
      });
    },
  },
};
</script>

<style></style>
