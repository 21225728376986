<template>
  <app-select
    label="Empresa"
    v-bind="$attrs"
    v-on="$listeners"
    :items="companies"
    :item-text="(val) => val.name"
    :item-value="(val) => val"
  />
    <!-- item-value="id" -->
</template>

<script>
export default {

  computed : {
    companies() {
      return this.$store.state.company.companies
    }
  }
}
</script>

<style>

</style>