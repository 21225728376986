<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    scrollable
    eager
    origin="center"
  >
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title> {{ title }} </v-card-title>
        <v-card-text>
          <app-text-field
            class="mt-3"
            label="Descrição"
            v-model="form.description"
          />
        </v-card-text>
        <v-card-text>
          <v-textarea label="Detalhes" v-model="form.details" rows="1" />
        </v-card-text>
        <v-card-text>
          <app-number-field label="Valor" v-model="form.value" />
        </v-card-text>
        <v-card-actions
          class="justify-end py-4"
          style="background-color: #f5f5f5"
        >
          <v-btn
            @click="dialog = false"
            text
            class="text-capitalize"
            color="secondary"
            >Cancelar</v-btn
          >
          <v-btn @click="handleSave()" class="text-capitalize" color="primary"
            >Salvar</v-btn
          >
        </v-card-actions>


      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import AccountPlanSearch from "@/components/account-plan/ui/AccountPlanSearch";
import CostCenterSearch from "@/components/cost-center/ui/CostCenterSearch";
import BillPayableFormParcels from "@/components/bill-payable/forms/BillPayableFormParcels";

export default {
  components: {
    CompanySelect,
    AccountPlanSearch,
    CostCenterSearch,
    BillPayableFormParcels,
  },

  data() {
    return {
      valid: true,
      title: null,
      dialog: false,

      form: {},

      baseForm: {
        account_plan: null,
        payment_method: "cash",
        cost_center: null,
        type: null,
        description: null,
        value: null,
        date: null,
        origin: "custom",
      },
    };
  },


  methods: {
    async open(type) {
      this.reset();


      if (type == "in") {
        this.form.type = "in";
        this.title = "Nova Entrada no Caixa";
      } else {
        this.form.type = "out";
        this.title = "Nova Saída no Caixa";
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    handleSave() {
      this.$refs.form.validate();
      if (this.valid == true) {
        // if (this.isInsert) {
        this.store();
        // } else {
        // this.update();
        // }
      }
    },

    store() {
      this.$http
        .store("account/account-transaction", this.form)
        .then((response) => {
          this.processSaved();
        });
    },

    // update() {
    //   this.$http
    //     .update("account/account-transaction", this.form.id, this.form)
    //     .then((response) => {
    //       console.log(response);
    //       this.processSaved();
    //     });
    // },

    processSaved() {
      this.$emit("store");
      this.dialog = false;
    },
  },
};
</script>


