<template>
  <v-dialog v-model="dialog" max-width="650">
    <v-card class="text-center">
      <v-card-title class="text-h5">Caixas Anteriores</v-card-title>

      <v-data-table
        :headers="headers"
        :items="shifts.data"
        :items-per-page="20"
        hide-default-footer
        dense
        :loading="loading"
        @click:row="selectShift($event)"
      >
        <template v-slot:[`item.started_by_user`]="{ item }">
          <span v-if="item.started_by_user != null">
            {{ item.started_by_user.name }}
          </span>
          <br />
          <span v-if="item.started_at != null">
            {{ $format.dateTimeBr(item.started_at) }}
          </span>
        </template>
        <template v-slot:[`item.ended_by_user`]="{ item }">
          <span v-if="item.ended_by_user != null">
            {{ item.ended_by_user.name }}
          </span>
          <br />
          <span v-if="item.ended_at != null">
            {{ $format.dateTimeBr(item.ended_at) }}
          </span>
        </template>
        <template v-slot:loading>Carregando.. </template>
        <template v-slot:no-data>Nenhum item encontrado</template>
      </v-data-table>

      <v-card-actions style="background-color: #f5f5f5">
        <v-spacer></v-spacer>
        <v-pagination
          v-model="searchParams.page"
          :length="shifts.last_page"
          @input="select($event)"
          :total-visible="9"
        />
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      shifts: {},
      dialog: false,
      loading: false,
      searchParams: {
        page: 1,
      },
      headers: [
        {
          align: "center",
          sortable: false,
          text: "Aberto por ",
          value: "started_by_user",
        },
        {
          align: "center",
          sortable: false,
          text: "Fechado por",
          value: "ended_by_user",
        },
      ],
    };
  },

  computed: {
    selectedAccount() {
      return this.$store.state.accounts.selectedAccount;
    },
  },

  methods: {
    async open() {
      await this.select();
      this.dialog = true;
    },

    selectShift(selectedShift) {
      this.$emit("input", selectedShift);
      this.dialog = false;
    },

    select(page = 1) {
      this.loading = true;
      this.searchParams.page = page;
      this.$http.index("account/shift", this.searchParams).then((response) => {
        this.shifts = response.shifts;
        this.loading = false;
      });
    },
  },
};
</script>

<style></style>
