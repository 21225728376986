<template>
  <v-dialog v-model="dialog" persistent width="400">
    <v-card>
      <v-card-title class="text-h5"> Fechamento de caixa </v-card-title>
      <v-card-text> Saldo em Dinheiro: 5000 </v-card-text>
      <v-card-actions>
        <app-number-field
          v-model="form.value"
          label="Informe o valor que esta na sua gaveta"
        />
      </v-card-actions>
      <v-card-text>Diferença de caixa: 5000 </v-card-text>
      <v-card-text
        >O valor acima representa a diferença do caixa após contar o dinheiro
        clique no botão próximo para continuar
      </v-card-text>

      <v-card-actions style="background-color: #f5f5f5">
        <v-spacer></v-spacer>

        <v-btn @click="dialog = false" text class="text-capitalize"
          >Cancelar</v-btn
        >
        <v-btn
          @click="closeShift()"
          class="text-capitalize mr-2"
          color="primary"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      form: { value: null },
      dialog: false,
    };
  },

  computed: {
    openedShift() {
      return this.$store.state.accounts.selectedAccount.opened_shift;
    },
  },

  methods: {
    open() {
      this.dialog = true;
    },
    async closeShift() {
      await this.$http
        .update("account/shift", this.openedShift.id)
        .then((response) => {
          this.$emit("close");
          this.dialog = false;
        });
    },
  },
};
</script>
