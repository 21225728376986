<template>
  <v-data-table
    :headers="headers"
    :items="accountTransactions.data"
    :items-per-page="20"
    hide-default-footer
    v-on="$listeners"
    disable-sort
    v-bind="$attrs"
  >
    <template v-slot:[`item.name`]="{ item }">
      <account-transaction-label :value="item" />
    </template>
    <template v-slot:[`item.value`]="{ item }">
      <b> {{ $format.decimal(item.value) }} </b>
    </template>
    <template v-slot:[`item.date`]="{ item }">
      {{ $format.dateBr(item.date) }}
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <account-transactions-status
        ref="AccountTransactionsStatus"
        :value="item.status"
      />
    </template>
    <template v-slot:loading>Carregando.. </template>
    <template v-slot:no-data>Nenhum item encontrado</template>
  </v-data-table>
</template>

<script>
import AccountTransactionLabel from "../section/AccountTransactionLabel.vue";
import AccountTransactionsStatus from "../section/AccountTransactionsStatus.vue";
export default {
  components: { AccountTransactionsStatus, AccountTransactionLabel },
  props: {
    accountTransactions: {},
  },
 
  data() {
    return {
      headers: [
        {
          text: "Cliente/Descrição",
          align: "start",
          value: "name",
        },
        { text: "Data", value: "date" },
        { text: "Status", value: "status", align: "center" },
        { text: "Valor", value: "value", align: "end" },
      ],
    };
  },
  methods: {},
};
</script>

<style></style>
