<template>
  <div>
    <template v-if="selectedAccountPlan">
      <app-text-field label="Plano de Contas" :value="selectedAccountPlan.name" readonly @click="open()" prepend-inner-icon="mdi-magnify" @keypress="open()"/> 
    </template>
    <template v-else>
      <div id="acc" @click.stop="open()">
        <app-text-field label="Plano de Contas" readonly @click="open()" prepend-inner-icon="mdi-magnify" /> 
      </div>
    </template>
    <!-- <div class="d-flex">
      <v-btn
        class="flex-grow-1 text-capitalize"
        
        color="secondary"
        @click="open()"
        outlined
      >
        <v-container class="pa-0">
          <v-row class="align-center">
            <v-col class="align-center pa-0 text-left">
              <template v-if="selectedAccountPlan">
                <span>{{ selectedAccountPlan.name }}</span>
              </template>

              <template v-else>
                <span>Selecionar Plano de Contas</span>
              </template>
            </v-col>

            <v-col class="pa-0 text-right">
              <v-btn
                v-if="selectedAccountPlan"
                text
                fab
                x-small
                @click.stop="clear()"
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-btn>
    </div> -->

    <v-dialog
      v-model="dialog"
      max-width="600px"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between" style="width: 100%">
            <div>
              <span>Selecionar plano de contas</span>
            </div>
          </div>
        </v-card-title>
        <v-card-subtitle>
          <v-text-field
            :loading="isLoading"
            class="mt-4"
            v-model="searchForm.searchText"
            autofocus
            solo
            flat
            background-color="grey lighten-4"
            prepend-inner-icon="mdi-magnify"
            placeholder="Pesquisar..."
            hide-details
            v-debounce:300ms="search"
          />
        </v-card-subtitle>
        <v-card-text>
          <v-list>
            <template v-for="(row, index) in accountPlans.data">
              <v-list-item :key="index" @click="setAccountPlan(row)">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="row.name"
                  ></v-list-item-title>

                  <v-list-item-title v-text="row.code"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- <v-icon :color="item.active ? 'deep-purple accent-4' : 'grey'">chat_bubble</v-icon> -->
                </v-list-item-icon>
              </v-list-item>
              <v-divider :key="'div' + index" />
            </template>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-capitalize" text @click="dialog = false"
            >Voltar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppTextField from '../../app/ui/AppTextField.vue';
export default {
  components: { AppTextField },
  props: {
    value: {},
    valueLabel: {},
  },

  data: () => ({
    accountPlans: {
      data: [],
    },

    selectedAccountPlan: {
      name : null
    },

    dialog: false,

    isLoading: false,

    searchForm: {
      searchText: null,
    },
  }),

  computed: {
    // accountPlans() {
    //   return this.$store.state.accountPlan.accountPlans;
    // },
  },

  watch: {
    value() {
      this.selectedAccountPlan = this.value;
    },
  },

  created() {
    this.selectedAccountPlan = this.value;
  },

  methods: {
    async open() {
      await this.getAccountPlans();

      this.dialog = true;
    },

    async getAccountPlans() {
      this.$http.index('account/account-plan', this.searchForm)
        .then(response => {
          this.accountPlans = response.accountPlans
        })
    },

    async search() {
      this.isLoading = true;

      await this.getAccountPlans();

      this.isLoading = false;
    },

    setAccountPlan(accountPlan) {
      this.selectedAccountPlan = accountPlan;

      this.$emit("input", accountPlan);

      this.dialog = false;
    },

    clear() {
      this.selectedAccountPlan = null;
    },
  },
};
</script>
