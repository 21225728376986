<template>
  <v-dialog v-model="dialog" max-width="600px" scrollable eager origin="center">
    <v-card>
      <v-card-title> {{ title }} </v-card-title>
      <v-card-text>
        <app-text-field
          readonly
          class="mt-3"
          label="Descrição"
          v-model="transaction.description"
        />
      </v-card-text>
      <v-card-text>
        <v-textarea
          readonly
          label="Detalhes"
          v-model="transaction.details"
          rows="1"
        />
      </v-card-text>
      <v-card-text>
        <app-number-field
          class="mt-3"
          label="Valor"
          readonly
          v-model="transaction.value"
        />
      </v-card-text>

      <v-card-actions
        class="justify-end py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Voltar</v-btn
        >
        <!-- <v-btn @click="handleSave()" class="text-capitalize" color="primary"
          >Salvar</v-btn
        > -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      transaction: {},
    };
  },
  computed: {
    title() {
      return this.transaction.type == "in"
        ? "Entrada no Caixa"
        : "Saida no Caixa";
    },
  },
  methods: {
    async open(id) {
      await this.$http
        .show("account/account-transaction", id)
        .then((response) => {
          this.transaction = response.accountTransaction;
          this.dialog = true;
        });
    },
  },
};
</script>

<style></style>
