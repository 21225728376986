var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"items":_vm.accountTransactions.data,"items-per-page":20,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('account-transaction-label',{attrs:{"value":item}})]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.value))+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.date))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('account-transactions-status',{ref:"AccountTransactionsStatus",attrs:{"value":item.status}})]}},{key:"loading",fn:function(){return [_vm._v("Carregando.. ")]},proxy:true},{key:"no-data",fn:function(){return [_vm._v("Nenhum item encontrado")]},proxy:true}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }